var jQuery = require( "jquery" );

(function ($) {
  'use strict';

  $(document).ready(function () {
    var $window = $(window);
    var $body = $('body');
    var $headerBtn = $('.js-dropdown-button');

    function GetDropdownWrapper($targetSibling) {
      if (!($targetSibling instanceof jQuery) || $targetSibling === undefined) return;
      return $targetSibling.next('.dropdown-wrapper');
    }

    function ToggleAnimationCTA() {
      var $ctaTarget = $('.js-animate-cta-trigger');

      function removeAllClass() {
        $ctaTarget.removeClass('slide-down slide-up');
      }

      return {
        slide_up:   function () { removeAllClass(); $ctaTarget.addClass('slide-up'); },
        slide_down: function () { removeAllClass(); $ctaTarget.addClass('slide-down'); },
        fade_in:    function () { removeAllClass(); $ctaTarget.fadeIn(400); },
        fade_out:   function () { removeAllClass(); $ctaTarget.fadeOut(400); }
      };
    }

    function ToggleOverlay() {
      var bodyOverlayClass = 'js-body-overlay';
      var bodyOverlayTransitionClass = 'js-overlay-transition';

      return {
        initEventListenter: function () {
          // Dismiss the menu when clicking on the overlay
          $body.on('click', function (e) {
            var $target = $(e.target);
            if ($target.hasClass(bodyOverlayClass)) LevelOneDropdown().resetUi();
          });
        },
        // Add or Remove classes one by one to have the overlay transition effect
        hide: function () {
          var currentPos = $body.offset().top;
          $body.removeClass(bodyOverlayTransitionClass).css('top', '');
          setTimeout(function () { $body.removeClass(bodyOverlayClass); }, 450);
          window.scrollTo(0, Math.abs(currentPos));
        },
        show: function () {
          var currentPos = window.pageYOffset;
          $body.addClass(bodyOverlayClass).css({ top: -currentPos + 'px' });
          setTimeout(function () { $body.addClass(bodyOverlayTransitionClass); }, 100);
        }
      };
    }

    function LevelOneDropdown() {
      var defaultTimer = 400;
      var wrapperClass = '.dropdown-wrapper.header-burger-dropdown';

      function getDirectDropdownWrapper() {
        return GetDropdownWrapper($headerBtn);
      }

      function closeAllLevelOne(timer) {
        var closeAllTimer = timer || defaultTimer;
        $(wrapperClass + ' .dropdown-toggle').removeClass('show');
        $(wrapperClass + ' .dropdown-wrapper').slideUp(closeAllTimer);
      }

      function resetUiFx(timer) {
        if (!$headerBtn.hasClass('active')) return;

        var resetUiTimer = timer || defaultTimer;
        var $activeDropdownWrapper = GetDropdownWrapper($headerBtn.filter('.active'));

        // Animation Sequence to close dropdown
        ToggleAnimationCTA().fade_out();
        getDirectDropdownWrapper().find('.level-1-mobile-menu-list').fadeOut(300);

        ToggleOverlay().hide();
        $activeDropdownWrapper.animate({ width: 'toggle' }, { queue: false }).promise().then(function resetOtherUi() {
          // Inside promise() to make sure it swipes right first before reseting the rest
          closeAllLevelOne(resetUiTimer);
          $headerBtn.removeClass('active').blur();
          $activeDropdownWrapper.css('display', '').blur();
        });
      }

      function resetMobileToDesktopUI() {
        if (!$headerBtn.hasClass('active')) return;

        if ($body.width() >= 992) {
          var $activeDropdownWrapper = GetDropdownWrapper($headerBtn.filter('.active'));

          $body.removeClass('js-body-overlay js-overlay-transition');
          $headerBtn.removeClass('active').blur();
          $activeDropdownWrapper.css('display', '');
        }
      }

      return {
        initEventListenter: function () {
          var $directDropdownWrapper = getDirectDropdownWrapper();
          var minWidthLaptop = 1024;

          // LEVEL 1 DROPDOWNS: handling the multiple dropdowns on the header
          $headerBtn.on('click', function click() {
            if ($body.width() >= minWidthLaptop) return;

            var $this = $(this);
            var $dropdownWrapper = GetDropdownWrapper($this);

            if ($this.hasClass('active')) {
              resetUiFx();
            } else {
              ScrollPosition().updatePrevPos();

              // Level 1 Header Menus: close dropdowns in the header except the one clicked
              $directDropdownWrapper.not(this).hide(150);

              // Animation Sequence to OPEN dropdown
              ToggleOverlay().show(); // Overlay first
              $dropdownWrapper.animate({ width: 'toggle' }); // Then show dropdown

              // Fade in the CTA button and the list
              $directDropdownWrapper.find('.level-1-mobile-menu-list').fadeIn(20);
              ToggleAnimationCTA().fade_in(); // needs to be faded in due to position fixed.
            }

            $headerBtn.not(this).removeClass('active');
            $this.toggleClass('active');
          });

          // Reset mobile JS UI when resize to desktop
          $headerBtn.on('mouseout', resetMobileToDesktopUI);
          $directDropdownWrapper.on('mouseout', resetMobileToDesktopUI);
        },
        getWrapperClass: function () {
          var wrapperClassEdsv2 = '.level-1-mobile-menu-list.js-scrolling-cta-trigger';

          return ($(wrapperClassEdsv2).length) ? wrapperClassEdsv2 : wrapperClass;
        },
        resetUi: function (timer) {
          resetUiFx(timer);
        }
      };
    }

    function ScrollPosition() {
      var $previousPos = null;
      var $scrollingElement = $(LevelOneDropdown().getWrapperClass());
      var $timeout; // Setup a timer

      function getValues() {
        return {
          currentScrollPos: function () {
            return $scrollingElement.scrollTop();
          },
          previousScrollPos: function () {
            return $previousPos;
          }
        };
      }

      function updatePrevPosFx($pos) {
        $previousPos = $pos || getValues().currentScrollPos();
      }

      function ctaScrollHandler() {
        var $currentScrollPos = getValues().currentScrollPos();
        var ctaTriggerAnimate = Math.round($window.height() * 0.25);
        var showButtonBufferPX = 15; // adding buffer px before showing the CTA.

        if ($timeout) window.cancelAnimationFrame($timeout);

        $timeout = window.requestAnimationFrame(function () {
          if ($currentScrollPos > getValues().previousScrollPos()) {
            if ($currentScrollPos >= ctaTriggerAnimate) ToggleAnimationCTA().slide_up();
          } else {
            if (($currentScrollPos + showButtonBufferPX < getValues().previousScrollPos()) || ($currentScrollPos <= ctaTriggerAnimate)) {
              ToggleAnimationCTA().slide_down();
            }
          }

          updatePrevPosFx($currentScrollPos);
        });
      }

      return {
        initEventListenter: function () {
          $scrollingElement.off('scroll'); // Turn OFF any previous event first if any to avoid duplicate events
          if ($body.width() < 768) $scrollingElement.on('scroll', ctaScrollHandler); // Activate on Mobile breakpoint only
        },
        updatePrevPos: function ($pos) {
          updatePrevPosFx($pos);
        }
      };
    }

    //-------------------------------------- EDSv2 functions ---------------------------------------
    function Edsv2() {
      function toggleNavBkgColor(self) {
        var $nav = $('.nav');

        (self.scrollTop() > 0) ? $nav.addClass('js-bg-white') : $nav.removeClass('js-bg-white');
      }

      function toggleMenuHoverClass() {
        if ($body.width() < 1024) return;

        $('.nav-menu-dropdown, .footer-dropdown').hover(
          function() {
            $(this).children(':first').addClass('active');
          }, function() {
            $(this).children(':first').removeClass('active');
          }
        );
      }

      return {
        initEventListenter: function () {
          $window.on('load', function () {
            toggleMenuHoverClass();
          });

          $window.on('scroll load', function () {
            toggleNavBkgColor($(this));
          });
        },
        // For resizing only
        toggleBodyOverlay: function () {
          if (!$headerBtn.hasClass('active')) return;

          // nav-menu-lang/nav-menu-burger ➤ .burger-menu-wrapper.dropdown-wrapper
          var $activeBurgerMenuWrapper = $headerBtn.filter('.active').next('.dropdown-wrapper');

          // ➤ Hide $activeBurgerMenuWrapper and Remove body classes
          var resizingBreakpoint = 1024;

          // when opening Burger Menu
          if ($body.width() >= resizingBreakpoint) {
            $body.removeClass('js-body-overlay js-overlay-transition');
            $activeBurgerMenuWrapper.hide();
          } else {
            $body.addClass('js-body-overlay js-overlay-transition');
            $activeBurgerMenuWrapper.show();
          }
        }
      };
    }
    //----------------------------------------------------------------------------------------------

    // Initialize Event listeners
    LevelOneDropdown().initEventListenter();
    ToggleOverlay().initEventListenter();
    ScrollPosition().initEventListenter();
    Edsv2().initEventListenter();

    // RESIZING: will hide menu on resize
    $window.on('resize', function () {
      ScrollPosition().initEventListenter(); // To turn on/off scroll event depending on screensize
      Edsv2().toggleBodyOverlay(); // Trigger classes by JS will avoid adding breakpoints to js- classes
    });

    //-------------------------------------- Redirect to Router UI ---------------------------------------
    function fetchPublicJSON() {
      $.get('/v2/info/public.json')
        .done(function() {
          setTimeout(fetchPublicJSON, 2000);
        })
        .fail(function() {
          window.location.reload();
        });
    }

    fetchPublicJSON();
  });
}(jQuery));
